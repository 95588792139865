import { React, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import TowelImg from './towel.jpg';
import TowelImg1 from './towel1.jpg';
import TowelImg2 from './towel2.jpg';
import TowelImg3 from './towel3.jpg';
import TowelImg4 from './towel4.jpg';
// import Axios from "axios";
import Base from "../Config/Base";
import { API_BASE_URL, HARD_CODED_ORGID, IBIZ_IMAGE_URL } from "../Config/Config";
import { useParams } from "react-router-dom";
// import ReactImageMagnify from 'react-image-magnify';
// import { Rate } from 'antd';
import { Rating } from 'react-simple-star-rating'
import Axios from "axios";
import ReactImageMagnify from 'react-image-magnify';


export default function ProductDetails() {

    const { ProductId } = useParams();

    const [loading, setLoading] = useState(false);
    const [productData, setProductData] = useState([]);
    const [productImages, setProductImages] = useState([]);
    const [mainImage, setMainImage] = useState(null);
    const [isZoomed, setIsZoomed] = useState(false);
    const [aboutItems, setAboutItems] = useState([]);

    useEffect(() => {
        if (ProductId){
            setLoading(true);
            const api = `${API_BASE_URL}QRProduct/GetAdditionalProdDetails?OrgId=${HARD_CODED_ORGID}&ProductId=201&StoreId=25&Type=Product`;
            Axios.get(api)
                .then(response => {
                    if (response.data.Status) {
                        setLoading(false);
                        setProductData(response.data.ResultData[0]);
                        // console.log('product details', response.data.ResultData[0]);
                        
                    } else {
                        setLoading(false);
                        console.error('Error fetching data:', response.data);
                        setProductData([]);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    console.error('Error fetching data:', error);
                });
        }
    }, [ProductId]);

    useEffect(() => {
        if (productData.ProductImages) {
            const imagesArray = productData.ProductImages.split(',').map(img => img.trim());
            setProductImages(imagesArray);

            setMainImage(imagesArray[0]);
        }
    }, [productData]);

    useEffect(() => {
        // Only proceed if productData.About exists
        if (productData?.About) {
            // Remove newline characters and split by `</li>` tags
            const items = productData.About.split('</li>').filter(item => item.trim() !== "");
            setAboutItems(items);
        }
    }, [productData]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <Base>
            <div className="page-wrapper ">
                <div className="content">
                    {loading ? <div className="loader">loading...</div> : 
                        <div className="row mx-3" style={{ marginTop: '10rem' }}>
                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <div className="card">
                                    <div>
                                        <span className="bookmark-sideleft-ribbone-success-left">
                                            <span>#Best Selling</span>
                                        </span>
                                    </div>
                                    <div className="card-body" >
                                        {/* Main Image Section */}
                                        <div
                                            className="mb-3"
                                            style={{ height: '40%', width: '100%', overflow: 'hidden', position: 'relative' }}
                                            onMouseEnter={() => setIsZoomed(true)}
                                            onMouseLeave={() => setIsZoomed(false)}
                                        >
                                            <ReactImageMagnify
                                                {...{
                                                    smallImage: {
                                                        alt: 'Main Product Image',
                                                        isFluidWidth: true,
                                                        src: `${IBIZ_IMAGE_URL}${mainImage}`
                                                    },
                                                    largeImage: {
                                                        src: `${IBIZ_IMAGE_URL}${mainImage}`,
                                                        width: 1200,
                                                        height: 1800
                                                    },
                                                    isHintEnabled: true,
                                                    shouldHideHintAfterFirstActivation: false,
                                                }}
                                            />
                                            {isZoomed && (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: '10%',
                                                        left: '100%',
                                                        transform: 'translateY(-50%)',
                                                        zIndex: 10000,
                                                        pointerEvents: 'none',
                                                    }}
                                                >
                                                    <ReactImageMagnify
                                                        {...{
                                                            smallImage: {
                                                                alt: 'Main Product Image',
                                                                isFluidWidth: true,
                                                                src: `${IBIZ_IMAGE_URL}${mainImage}`
                                                            },
                                                            largeImage: {
                                                                src: `${IBIZ_IMAGE_URL}${mainImage}`,
                                                                width: 1200,
                                                                height: 1800
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        
                                        {/* Thumbnail Images Section */}
                                        <div className="d-flex justify-content-between">
                                            {productImages?.map((img, index) => (
                                                <div
                                                    key={index} 
                                                    className="col-2 border"
                                                    style={{ height: '10%', overflow: 'hidden' }}
                                                    onClick={() => setMainImage(img)}
                                                >
                                                    <img 
                                                        src={`${IBIZ_IMAGE_URL}${img}`} 
                                                        alt={`Thumbnail ${index + 1}`} 
                                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <div className="my-2">
                                    <h3>{productData?.Name}</h3>
                                    <div>
                                        <p className="fs-5">{productData?.AvgRating} 
                                            <Rating initialValue={productData?.AvgRating} />
                                            <span className="text-info fs-5">{productData?.RatingCount} ratings</span>
                                        </p>
                                    </div>
                                    <div className="d-flex mt-3 align-items-center">
                                        <p className="text-danger fw-semibold fs-5 mb-0">-{productData?.MaxDiscountValue}%</p>
                                        
                                        {/* Calculate the discounted price */}
                                        <h2 className="ms-2 mb-0">
                                            ₹{productData?.MRP ? (productData.MRP - (productData.MaxDiscountValue / 100) * productData.MRP).toFixed(2) : '0.00'}
                                        </h2>
                                        
                                        <p className="fs-6 fw-semibold ms-auto mb-0">
                                            MRP: <span className="text-decoration-line-through text-muted">₹{productData?.MRP}</span>
                                        </p>
                                    </div>
                                </div>

                                <div className="card">
                                    <span class="ribbone-info-left">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 25 20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
                                        </span>
                                    </span>
                                    <div className="card-body">
                                        <table className="table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td>Brand</td>
                                                    <th>{productData?.BrandName}</th>
                                                </tr>
                                                <tr>
                                                    <td>Category</td>
                                                    <th>{productData?.CategoryName}</th>
                                                </tr>
                                                <tr>
                                                    <td>Barcode</td>
                                                    <th>{productData?.BarCode}</th>
                                                </tr>
                                                <tr>
                                                    <td>Sale Unit</td>
                                                    <th>{productData?.SaleUnitName}</th>
                                                </tr>
                                                <tr>
                                                    <td>MFD</td>
                                                    <th>{formatDate(productData.ManufacturedDate)}</th>
                                                </tr>
                                                <tr>
                                                    <td>EXP</td>
                                                    <th>{formatDate(productData.ExpiredDate)}</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="my-2">
                                    <h6>About this item:</h6>
                                    <div>
                                        <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                        {aboutItems.map((item, index) => (
                                            <li key={index} dangerouslySetInnerHTML={{ __html: item + '</li>' }} />
                                        ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Base>
    );
}
