
// const API_BASE_URL = 'https://ibizapi.ibizaccounts.in/';

const API_BASE_URL = 'https://qaibizapi.ibizaccounts.in/';

// const API_BASE_URL = 'http://192.168.1.9:3007/';

// const API_BASE_URL = 'http://localhost:3009/';

// Image url
const IBIZ_IMAGE_URL = 'https://pcuploadfiles.azurewebsites.net/download?path=';
// const IBIZ_IMAGE_URL = 'http://files.perennialcode.com/';

const HARD_CODED_ORGID = 41112;

export { API_BASE_URL, HARD_CODED_ORGID, IBIZ_IMAGE_URL };