import { React, useEffect, useState, useRef } from "react";
import { API_BASE_URL, HARD_CODED_ORGID } from "../Config/Config";
import Select from 'react-select';
import QRCode from "react-qr-code";
import PropTypes from 'prop-types';
import Axios from "axios";
import html2canvas from "html2canvas";



const GenQRCode = ({ ProductId }) => {

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [qrValue, setQrValue] = useState("");
    const [options, setOptions] = useState([]);
    const [productData, setProductData] = useState([]);
    const [qRcodeType, setQRcodeType] = useState([]);
    const [tableHtml, setTableHtml] = useState('');
    const isProductIdProvided = ProductId > 0;
    const qrRef = useRef();

    useEffect(() => {
        if (selectedProduct || ProductId){
            const api = `${API_BASE_URL}QRProduct/GetProductsById?ProductId=${[ProductId]}`;
            Axios.get(api)
                .then(response => {
                    if (response.data.Status) {
                        setProductData(response.data.ResultData[0]);
                        // console.log('product details', response.data.ResultData[0]);
                        
                    } else {
                        console.error('Error fetching data:', response.data);
                        setProductData([]);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [selectedProduct, ProductId]);

    useEffect(() => {
        if (HARD_CODED_ORGID){
            const api = `${API_BASE_URL}QRProduct/GetProductsByOrgId?OrgId=${HARD_CODED_ORGID}&BrandId=0&CategoryId=0&Type=0`;
            Axios.get(api)
                .then(response => {
                    if (response.data.Status) {
                        const resultData = response.data.ResultData;
                        const transformedOptions = resultData.map(product => ({
                            value: product.Id,
                            label: product.Name,
                        }));
                        const defaultOption = [{ value: '', label: 'Choose Product', isDisabled: true }];
                        setOptions([...defaultOption, ...transformedOptions]);

                        if (isProductIdProvided) {
                            const selected = transformedOptions.find(option => option.value === ProductId);
                            if (selected) {
                                setSelectedProduct(selected);
                                generateQRCode(selected);
                            }
                        }
                    } else {
                        console.error('Error fetching data:', response.data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [ProductId]);

    const generateQRCode = (product) => {
        if (product) {
            const encodedOrgId = btoa(HARD_CODED_ORGID.toString());
            const encodedId = btoa(product.value.toString());
            const qrCodeValue = `https://beta.ibizaccounts.com/product-info/${encodedId}?OrgId=${encodedOrgId}`;
            setQrValue(qrCodeValue);
        }
    };

    const handleGenerateQRCode = () => {
        if (selectedProduct) {
            generateQRCode(selectedProduct);
        } else {
            alert("Please select a product to generate a QR code");
        }
    };

    const handleProductChange = (product) => {
        setSelectedProduct(product);
        generateQRCode(product);
    };

    useEffect(() => {
        if (qrValue){
            const api = `${API_BASE_URL}QRProduct/GetBarcodeType?OrgId=${HARD_CODED_ORGID}`;
            Axios.get(api)
                .then(response => {
                    if (response.data.Status) {
                        setQRcodeType(response.data.ResultData[0]);
                        // console.log('htmlstring from api', response.data.ResultData[0].HtmlString);
                        let htmlContent = response.data.ResultData[0].HtmlString; 
                        htmlContent = htmlContent
                        .replace('{Name}', productData.Name)
                        .replace('{Barcode}', productData.BarCode)
                        .replace('{Description}', productData.Description)
                        .replace('{Price}', productData.AvgPrice);
    
                    setTableHtml(htmlContent);
                    // console.log(htmlContent, 'htmlContenthtmlContenthtmlContenthtmlContent');
                    } else {
                        console.error('Error fetching data:', response.data);
                        setQRcodeType([]);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [qrValue]);
    
    const downloadQRCode = async () => {
        const canvas = await html2canvas(qrRef.current);
        const pngUrl = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "qrcode.png"; // Specify the file name
        downloadLink.click();
    };
    

    return (
        <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasRightGenQR" aria-labelledby="offcanvasRightLabel"
            style={{ minWidth: '30%', maxWidth: '30%' }}>
            <div className="offcanvas-header">
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <hr />
            <div className="offcanvas-body">
                <div className="row">
                    <div className="col-12">
                        <label className="form-label">Product</label>
                        <Select 
                            options={options}
                            value={selectedProduct}
                            onChange={handleProductChange}
                            placeholder="Choose Product"
                            isDisabled={isProductIdProvided}
                        />
                    </div>
                    {qrValue && 
                    <div className="mt-3">
                        <button onClick={downloadQRCode} className="btn d-flex ms-auto bg-white"><i className="fa-solid fa-download fs-5 shadow text-primary bg-light"></i></button>
                    </div>
                    }
                    {!isProductIdProvided && (
                        <div className="mt-4">
                            <button className="btn btn-primary d-flex m-auto" onClick={handleGenerateQRCode}>
                                Generate QR Code
                            </button>
                        </div>
                    )}
                    {qrValue && (
                        <div>
                            <div ref={qrRef} className="mt-3" style={{ height: "auto", margin: "0 auto", maxWidth: 226, width: "100%" }}>
                                <QRCode
                                    size={246}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%", borderRadius: '5px' }}
                                    value={qrValue}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                            <div className="mt-5"
                                dangerouslySetInnerHTML={{ __html: tableHtml }} 
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>   
    );
}

GenQRCode.propTypes = {
    ProductId: PropTypes.number.isRequired,
};

export default GenQRCode;
