import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import PendingPayment from '../../Assests/Images/pendingpayment.svg'
// import PaidPayment from '../../Assests/Images/paidpayment.svg'
// import PurchaseServed from '../../Assests/Images/purchaseserved.svg'
// import CompletedPOs from '../../Assests/Images/completedpurchases.svg'
import Axios from "axios";
import Base from "../Config/Base";
import { API_BASE_URL, HARD_CODED_ORGID } from "../Config/Config";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import GenQRCode from "../QRCode/GenQRCode";


export default function Products() {

    const [productsData, setProductsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [genQRProdId, setGenQRProdId] = useState(0);

    useEffect(() => {
        if (HARD_CODED_ORGID){
            setLoading(true);
            const api = `${API_BASE_URL}QRProduct/GetProductsByOrgId?OrgId=${HARD_CODED_ORGID}&BrandId=0&CategoryId=0&Type=0`;
            Axios.get(api)
                .then(response => {
                    if (response.data.Status) {
                        setProductsData(response.data.ResultData);
                        // console.log(response.data.ResultData);
                        setLoading(false);
                    } else {
                        console.error('Error fetching data:', response.data);
                        setProductsData([]);
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                });
        }
    }, []);

    const totalItems = productsData?.length || 0;
    const itemsPerPage = 10;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const [currentPage, setCurrentPage] = useState(1);
    const [maxPagesToShow] = useState(3); // Show 3 page numbers at a time

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Array.isArray(productsData) ? productsData.slice(indexOfFirstItem, indexOfLastItem) : [];

    const startItem = indexOfFirstItem + 1;
    const endItem = indexOfLastItem > totalItems ? totalItems : indexOfLastItem;

   
    const filteredItems = currentItems.filter(item => {
        const name = String(item.Name || '').toLowerCase();
        const barCode = String(item.BarCode || '').toLowerCase();
        const category = String(item.CategoryName || '').toLowerCase();

        return (
            name.includes(searchQuery.toLowerCase()) ||
            barCode.includes(searchQuery.toLowerCase()) ||
            category.includes(searchQuery.toLowerCase()) 
        );
    });

    const generatePDF = () => {
        const doc = new jsPDF();
    
        doc.setProperties({
          title: " Report",
          author: "Your Name",
          creator: "Your Application",
        });
    
        const tableData = [];
    
        const headers = ["Name", "Category", "Barcode", "Price", "Mrp"];
        tableData.push(headers);
    
        productsData.forEach((item) => {
          const row = [];
          row.push(
            item.Name,
            item.CategoryName,
            item.BarCode,
            item.AvgPrice,
            item.MRP
          );
          tableData.push(row);
        });
    
        doc.autoTable({
          head: [headers],
          body: tableData.slice(1),
        });
    
        doc.save("ProductsData.pdf");
    };
    
    const generateEXCEL = () => {
        if (productsData.length === 0) {
            return;
        }

        const wb = XLSX.utils.book_new();

        const headers = ["Name", "Category", "Barcode", "Price", "Mrp"];

        const wsData = productsData?.map((item) => ({
            "Name": `${item.Name}`,
            "Category": item.CategoryName,
            "Barcode": item.BarCode,
            "Price": item.AvgPrice,
            "Mrp": item.MRP,
        }));

        wsData.unshift({
            "Name": headers[0],
            "Category": headers[1],
            "Barcode": headers[2],
            "Price": headers[3],
            "Mrp": headers[34],
        });

        const ws = XLSX.utils.json_to_sheet(wsData, { skipHeader: true });

        XLSX.utils.book_append_sheet(wb, ws, "ProductsData");

        XLSX.writeFile(wb, "ProductsData.xlsx");
    };

    const getFirstLetter = (str) => {
        // Check if str is defined and is a string
        if (typeof str === 'string') {
            const match = str.match(/[a-zA-Z]/);
            return match ? match[0].toUpperCase() : "N/A";
        }
        return "N/A"; // Return "N/A" if str is not a valid string
    };    

    const showViewModal = (dataObj) => {
        setGenQRProdId(dataObj);
      };

    return (
        <Base>
            <div className="page-wrapper ">
                <div className="content  mt-3 mt-md-0 mt-lg-0" style={{ marginTop: '-6rem' }}>
                    <div className="d-flex mb-3">
                        <a href="/">
                            <i className="fa-solid fa-house me-1 mt-1 text-primary"></i>
                        </a>
                        <i className="fa-solid fa-angles-right me-1 mt-1"></i>
                        <h4>Products</h4>
                    </div>
                    <div className="card flex-fill default-cover w-100 mb-4">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <i 
                                    className="fa-regular fa-file-pdf me-3 text-danger fs-4"
                                    style={{ cursor: 'pointer' }}
                                    title="Download PDF"
                                    onClick={generatePDF}
                                ></i>
                                <img 
                                    src="assets/img/icons/excel.svg" 
                                    style={{ width: '1.8rem', cursor: 'pointer' }}
                                    title="Download Excel"
                                    onClick={generateEXCEL}
                                />
                            </div>
                            <div className="dropdown">
                                <input
                                    className="form-control"
                                    placeholder="Search..."
                                    style={{ height: '2rem' }}
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive product-list">
                                <div className="table-responsive">
                                    <table className="table ">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Category</th>
                                                <th>Barcode</th>
                                                <th>MRP</th>
                                                <th>Price</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredItems && filteredItems.map((item, index) => (
                                                <tr className="odd" key={index}>
                                                    <td style={{ width: '100px' }}>
                                                        <div className="productimgname">
                                                            <Link to={`/product-info/${item.Id}`} className="product-img stock-img">
                                                                {item.ImagePath ? (
                                                                    <img
                                                                        src={`https://pcuploadfiles.azurewebsites.net/download?path=${item.ImagePath}`}
                                                                        alt=""
                                                                        style={{
                                                                        width: "40px",
                                                                        height: "40px",
                                                                        marginRight: "10px",
                                                                        borderRadius: "50%",
                                                                        }}
                                                                    />
                                                                    ) : (
                                                                    <div
                                                                        style={{
                                                                        width: "40px",
                                                                        height: "40px",
                                                                        backgroundColor: "#FF9F43",
                                                                        marginRight: "10px",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        borderRadius: "50%",
                                                                        fontSize: "18px",
                                                                        color: "#fff",
                                                                        }}
                                                                    >
                                                                        {item ? getFirstLetter(item.Name) : "N/A"}
                                                                    </div>
                                                                )}
                                                            </Link>
                                                            <Link to={`/product-info/${item.Id}`} title={item.Name}>
                                                                {item.Name.length > 15 ? `${item.Name.substring(0, 15)}...` : item.Name}
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td>{item.CategoryName || 'N/A'} </td>
                                                    <td style={{ width: '80px' }}>{item.BarCode || 'N/A'}</td>
                                                    <td>{item.MRP || 0}</td>
                                                    <td>{item.AvgPrice || 0}</td>
                                                    <td className="action-table-data">
                                                        <div className="edit-delete-action">
                                                            <Link 
                                                                className="me-2 p-2" 
                                                                data-bs-toggle="offcanvas"
                                                                data-bs-target="#offcanvasRightGenQR"
                                                                aria-controls="offcanvasRightGenQR"
                                                                onClick={() => showViewModal(item.Id)}
                                                            >
                                                                <i className="fa-solid fa-qrcode"></i>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                
                                    <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-between align-items-center">
                                        <div className="dataTables_info mt-0" id="DataTables_Table_0_info" role="status" aria-live="polite">
                                            {startItem} - {endItem} of {totalItems} items
                                        </div>
                                        <ul className="pagination mb-0">
                                            <li className={`paginate_button page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <a
                                                    className="page-link"
                                                    onClick={() => setCurrentPage(currentPage - 1)}
                                                >
                                                    <i className="fa fa-angle-left"></i>
                                                </a>
                                            </li>
                                            {Array.from({ length: Math.ceil(totalItems / itemsPerPage) }, (_, index) => (
                                                <li className={`paginate_button page-item ${currentPage === index + 1 ? 'active' : ''}`} key={index}>
                                                    <a
                                                        className="page-link"
                                                        onClick={() => setCurrentPage(index + 1)}
                                                    >
                                                        {index + 1}
                                                    </a>
                                                </li>
                                            ))}
                                            <li className={`paginate_button page-item ${currentPage === Math.ceil(totalItems / itemsPerPage) ? 'disabled' : ''}`}>
                                                <a
                                                    className="page-link"
                                                    onClick={() => setCurrentPage(currentPage + 1)}
                                                >
                                                    <i className="fa fa-angle-right"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <GenQRCode ProductId={genQRProdId} />
                </div>
            </div>


        </Base>
    );
}
