
import { React, useEffect, useState } from "react";
import { IBIZ_IMAGE_URL } from "../Config/Config";


export default function CartItems() {

    const [userDataSession, setUserDataSession] = useState([]);
    const [cartItems, setCartItems] = useState(null);


    useEffect(() => {
        const userDataString = sessionStorage.getItem("userData");
        const cartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
        
        setCartItems(cartItems);
        console.log('cartItems:', cartItems);
        
        if (userDataString) {
            const userData = JSON.parse(userDataString);
            setUserDataSession(userData);
        } else {
            console.log("User data not found in sessionStorage");
        }
    }, []);

    const handleClearCart = () => {
        sessionStorage.removeItem('cartItems');
        setCartItems([]);
        alert("All items have been removed from the cart.");
    };
    

    const handleDeleteItem = (id) => {
        const cartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
        const updatedCartItems = cartItems.filter(item => item.Id !== id);
        sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
        setCartItems(updatedCartItems);
        
    };

    return (
        <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasRightCartItems"
            aria-labelledby="offcanvasRightLabel"
            style={{ minWidth: '40%', maxWidth: '40%' }}
        >
            <div className="offcanvas-header">
                <h4 id="offcanvasRightLabel">Cart Items</h4>
                <div className="d-flex ms-auto align-items-center">
                    <button
                        type="button"
                        className="btn-close text-reset ms-2"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    />
                </div>
            </div>
            <hr />
            <div className="offcanvas-body">
                <div className="col-12" style={{ marginTop: '-2rem' }}>
                    <aside className="product-order-list">
                        <div className="head d-flex align-items-center justify-content-between w-100">
                            <div className="">
                                <h5>Order List</h5>
                                <span>Transaction ID : #65565</span>
                            </div>
                            <div className="">

                            </div>
                        </div>

                        <div className="product-added block-section">
                            <div className="head-text d-flex align-items-center justify-content-between">
                                <h6 className="d-flex align-items-center mb-0">Products Added<span className="count">{cartItems?.length}</span></h6>
                                <a 
                                    className="d-flex align-items-center text-danger" 
                                    onClick={handleClearCart}
                                >
                                    <i className="fa-solid fa-xmark me-2" ></i>Clear all
                                </a>
                            </div>
                            <div className="product-wrap">
                                {cartItems && cartItems?.map((item, index) => (
                                    <div className="product-list d-flex align-items-center justify-content-between" key={index}>
                                        <div className="d-flex align-items-center product-info" data-bs-toggle="modal" data-bs-target="#products">
                                            <a className="img-bg">
                                                <img src={`${IBIZ_IMAGE_URL}${item.ImagePath}`} alt="Products"/>
                                            </a>
                                            <div className="info">
                                                <span>{item.BarCode}</span>
                                                <h6><a>{item.Name}</a></h6>
                                                <p>₹{item.AvgPrice}</p>
                                            </div>
                                        </div>
                                        <div className="qty-item text-center">
                                            <a className="dec d-flex justify-content-center align-items-center"><i className="fa-regular fa-square-minus"></i></a>
                                            <input type="text" className="form-control text-center" name="qty" value={item.quantity}/>
                                            <a className="inc d-flex justify-content-center align-items-center"><i className="fa-regular fa-square-plus"></i></a>
                                        </div>
                                        <div className="d-flex align-items-center action">
                                            <a className="btn-icon edit-icon me-2" href="#">
                                                <i className="fa-regular fa-pen-to-square text-info"></i>
                                            </a>
                                            <a 
                                                className="btn-icon delete-icon confirm-text"
                                                onClick={() => handleDeleteItem(item.Id)}
                                            >
                                                <i className="fa-regular fa-trash-can"></i>
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="d-grid btn-block">
                            <a className="btn btn-secondary">
                                Grand Total : ₹{cartItems?.reduce((total, item) => total + (item.AvgPrice * item.quantity), 0).toFixed(2)}
                            </a>
                        </div>
                        <div className="btn-row d-sm-flex align-items-center justify-content-between">
                            <a className="btn btn-info btn-icon flex-fill"><span className="me-1 d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-pause feather-16"><rect x="6" y="4" width="4" height="16"></rect><rect x="14" y="4" width="4" height="16"></rect></svg></span>Hold</a>
                            
                            <a className="btn btn-success btn-icon flex-fill"><span className="me-1 d-flex align-items-center"><i className="fa-regular fa-credit-card me-2"></i></span>Payment</a>
                        </div>

                    </aside>
                </div>
            </div>
        </div>   
    );
}

