
import React, { useState, useEffect } from 'react';
import IbizLogo from "../../Assests/Images/iBizAccounts.png";
import FooterLogo from "../../Assests/Images/footerlogo.jpeg";
import { Link } from 'react-router-dom';
import UserIcon from '../../Assests/Images/usericon.svg';
import Axios from "axios";
import { API_BASE_URL } from './Config';
import GenQRCode from '../QRCode/GenQRCode';
import '../../Assests/CSS/Base.css';
import CartItems from './../Cart/Cart';


const Base = ({ children }) => {

    const [userDataSession, setUserDataSession] = useState([]);
    const [notifysData, setNotifysData] = useState([]);
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [cartItems, setCartItems] = useState(null);

    useEffect(() => {
        const userDataString = sessionStorage.getItem("userData");
        const cartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
        
        // Only set cart items on mount
        setCartItems(cartItems);
        console.log('cartItems:', cartItems);
        
        if (userDataString) {
            const userData = JSON.parse(userDataString);
            setUserDataSession(userData);
        } else {
            console.log("User data not found in sessionStorage");
        }
    }, []);

    useEffect(() => {
        if (userDataSession && userDataSession.OrgId){
            const api = `${API_BASE_URL}Misc/getNotifications?UserId=${userDataSession.UserId}&OrgId=${userDataSession.OrgId}`;
            Axios.get(api)
                .then(response => {
                    if (response.data.Status) {
                        setNotifysData(response.data.ResultData);
                        // console.log(response.data.ResultData, 'notify data ......');
                    } else {
                        console.error('Error fetching data:', response.data);
                        setNotifysData([]);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [userDataSession]);

    const toggleAccordion = (index, event) => {
        event.stopPropagation();
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const handleLogout = () => {

        sessionStorage.removeItem("userData");
        sessionStorage.removeItem("otp")

        setUserDataSession(null);
        window.location.href = "/";
        console.log("User logged out");
    };
    

    return (
        <div className="main-wrapper bg-light">
            {/* Header */}
            <div className="header d-flex justify-content-between align-items-center">
                <div className="header-left active">
                    <a href="/" className="logo logo-normal">
                        <img src={IbizLogo} alt="" />
                    </a>
                    <a href="/" className="logo logo-white">
                        <img src={IbizLogo} alt="" />
                    </a>
                    <a href="/" className="logo-small">
                        <img src={IbizLogo} alt="" />
                    </a>
                    {/* <a id="toggle_btn">
                        <i data-feather="chevrons-left" className="feather-16"></i>
                    </a> */}
                </div>

                <div className="header-center text-center flex-grow-1 d-none d-md-block d-lg-block">
                    <h3 className="store-name m-0 text-primary">
                        <i className="fa-solid fa-store me-1"></i>
                        Meghavi Wellness Spa
                    </h3>
                </div>
                <div className="header-center text-center flex-grow-1 d-none d-md-block d-lg-block">
                    <h5 className="store-name m-0">
                        <i className="fa-solid fa-phone me-1 text-info"></i>
                        <span>98985637281</span>
                    </h5>
                </div>

                <ul className="nav user-menu">
                    <li className="nav-item nav-searchinputs">
                        <div className="top-nav-search">
                            <a className="responsive-search">
                                <i className="fa fa-search"></i>
                            </a>
                            <form action="#" className="dropdown"></form>
                        </div>
                    </li>

                    <li 
                        className="nav-item dropdown nav-item-box"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRightCartItems"
                        aria-controls="offcanvasRightCartItems"
                        style={{ cursor: 'pointer' }}
                    >
                        <Link>
                            <i className="fa-solid fa-cart-shopping fs-5 text-dark"></i>
                            <span 
                                className={`badge rounded-pill ${cartItems && cartItems.length > 0 ? 'blinking-badge' : ''}`}
                                style={{ width: '1.2rem', height: '1.2rem' }}
                            >
                                {(cartItems && cartItems.length) || 0}
                            </span>
                        </Link>
                    </li>

                    <li className="nav-item dropdown nav-item-box">
                        <Link
                            to="#"
                            className="dropdown-toggle nav-link"
                            data-bs-toggle="dropdown"
                        >
                            <i className="fa-regular fa-bell fa-shake"></i>
                            <span 
                                className={`badge rounded-pill ${notifysData.length > 0 ? 'blinking-badge' : ''}`}
                                style={{ width: '1.2rem', height: '1.2rem' }}
                            >
                                {notifysData.length || 0}
                            </span>
                        </Link>
                        <div className="dropdown-menu notifications">
                            <div className="topnav-dropdown-header">
                                <span className="notification-title">Notifications</span>
                                <Link to="#" className="clear-noti">Clear All</Link>
                            </div>

                            <div className="noti-content">
                                <ul className="notification-list">
                                {notifysData && notifysData.map((item, index) => {
                                    const getTimeDifference = (createdOn) => {
                                    const now = new Date();
                                    const createdDate = new Date(createdOn);

                                    const diffInMs = now - createdDate;
                                    const diffInMinutes = Math.floor(diffInMs / 1000 / 60);
                                    const diffInHours = Math.floor(diffInMinutes / 60);
                                    const diffInDays = Math.floor(diffInHours / 24);

                                    if (diffInMinutes < 60) {
                                        return `${diffInMinutes} mins ago`;
                                    } else if (diffInHours < 24) {
                                        return `${diffInHours} hours ago`;
                                    } else {
                                        return `${diffInDays} days ago`;
                                    }
                                    };

                                    return (
                                    <li  className={`notification-message ${expandedIndex === index ? 'active' : ''}`}
                                        key={index}
                                        onClick={(event) => toggleAccordion(index, event)}
                                    >
                                        <Link to="#">
                                            <div className="media d-flex">
                                                <span className="avata flex-shrink-0">
                                                {expandedIndex === index ? (
                                                    <i className="fa-regular fa-envelope-open text-primary me-2 fs-6"></i>
                                                ) : (
                                                    <i className="fa-regular fa-envelope me-2 fs-6"></i>
                                                )}
                                                </span>
                                                <div className="media-body flex-grow-1">
                                                    <p className="noti-details">
                                                        <span className="noti-title">{item.ScreenText}</span>
                                                    </p>
                                                    <p className="noti-time">
                                                        <span className="notification-time">
                                                            {getTimeDifference(item.CreatedOn)}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>

                                        {expandedIndex === index && (
                                        <div className="accordion-body ms-3">
                                            {item.EmailBody ? (
                                            <p
                                                dangerouslySetInnerHTML={{
                                                __html: item.EmailBody.replace(/\r?\n|\r/g, '<br />'),
                                                }}
                                            />
                                            ) : (
                                            <p>No Body available</p>
                                            )}
                                        </div>
                                        )}
                                    </li>
                                    );
                                })}
                                </ul>
                            </div>
                            
                            <div className="topnav-dropdown-footer">
                                <Link to="#">View all Notifications</Link>
                            </div>
                        </div>
                    </li>

                    <li className="nav-item dropdown has-arrow main-drop">
                        <a className="dropdown-toggle nav-link userset" data-bs-toggle="dropdown">
                            <span className="user-info">
                                <span className="user-letter text-primary fs-3"><i className="fa fa-user-circle"></i></span>
                                {/* <span className="user-letter text-primary fs-3">{userDataSession?.SupplierName?.charAt(0)}</span> */}
                                <span className="user-detail">
                                    <span className="user-name text-warning">
                                        {userDataSession.SupplierName}
                                    </span>
                                    <h5 className="profilesets">User</h5>
                                    <span className="user-role"></span>
                                </span>
                            </span>
                        </a>
                        <div className="dropdown-menu menu-drop-user">
                            <div className="profilename">
                                <div className="profileset">
                                    <span className="user-img">
                                        <img src={UserIcon} alt="usericon" />
                                        {/* <span className="status online"></span> */}
                                    </span>
                                    <div className="profilesets">
                                        {/* <h5 className='text-warning'>{userDataSession.SupplierName}</h5> */}
                                        <h6>User</h6>
                                    </div>
                                </div>
                                <hr className="m-0" />
                                <Link to="/product-list">
                                    <a
                                        className="dropdown-item logout pb-0 text-secondary"
                                    >
                                       <i className="fa-solid fa-cubes me-2"></i>
                                        Products
                                    </a>
                                </Link>
                                <Link 
                                    // data-bs-toggle="offcanvas"
                                    // data-bs-target="#offcanvasRightGenQR"
                                    // aria-controls="offcanvasRightGenQR"
                                >
                                    <a
                                        className="dropdown-item logout pb-0 text-secondary"
                                    >
                                       <i className="fa-solid fa-qrcode me-2"></i>
                                        Generate QR
                                    </a>
                                </Link>
                                
                                <hr className="m-0" />
                                <Link to="/">
                                    <a className="dropdown-item logout pb-0" onClick={handleLogout}>
                                        <i className="fa-solid fa-right-from-bracket me-2"></i>
                                        Logout
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </li>
                </ul>

                {/* Mobile */}
                <div className="dropdown mobile-user-menu">
                    <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa fa-ellipsis-v"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item text-secondary" href="product-list">
                            <i className="fa-solid fa-cubes me-2 "></i> Products
                        </a>
                        <a 
                            className="dropdown-item text-secondary"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRightCartItems"
                            aria-controls="offcanvasRightCartItems"
                        >
                            <i className="fa-solid fa-cart-shopping text-dark me-2"></i> Cart
                        </a>
                        <Link 
                            className="dropdown-item text-secondary"
                            // data-bs-toggle="offcanvas"
                            // data-bs-target="#offcanvasRightGenQR"
                            // aria-controls="offcanvasRightGenQR"
                        >
                            <i className="fa-solid fa-qrcode me-2"></i> Generate QR
                        </Link>
                        <a className="dropdown-item text-danger" onClick={handleLogout}>
                            <i className="fa-solid fa-right-from-bracket me-2"></i> Logout
                        </a>
                    </div>
                </div>
            </div>
            
            {/* Page Content */}
            <div className="page-wrapper ">
                <div className="content">
                   {children}
                </div>
            </div>
            
            {/* Footer */}
            <div className="pg-footer">
                <footer className="footer">
                    <svg className="footer-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100"
                        preserveAspectRatio="none">
                        <path className="footer-wave-path"
                            d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z">
                        </path>
                    </svg>
                    <div className="footer-content" >
                        <div className="footer-content-column">
                            <div className="footer-logo">
                                <a className="footer-logo-link" href="#">
                                    <img src={FooterLogo} alt='footerlogo' className='rounded' />
                                </a>
                            </div>
                            <div className="footer-menu">
                                <h2 className="footer-menu-name"> Get Started</h2>
                                <ul id="menu-get-started" className="footer-menu-list">
                                    <li className="menu-item menu-item-type-post_type menu-item-object-product">
                                        <a href="#">Start</a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-product">
                                        <a href="#">Documentation</a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-product">
                                        <a href="#">Installation</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-content-column">
                            <div className="footer-menu">
                                <h2 className="footer-menu-name"> Company</h2>
                                <ul id="menu-company" className="footer-menu-list">
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="#">Contact</a>
                                    </li>
                                    <li className="menu-item menu-item-type-taxonomy menu-item-object-category">
                                        <a href="#">News</a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="#">Careers</a>
                                    </li>
                                </ul>
                            </div>
                            {/* <div className="footer-menu">
                                <h2 className="footer-menu-name"> Legal</h2>
                                <ul id="menu-legal" className="footer-menu-list">
                                    <li
                                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
                                        <a href="#">Privacy Notice</a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="#">Terms of Use</a>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                        <div className="footer-content-column">
                            <div className="footer-menu">
                                <h2 className="footer-menu-name"> Quick Links</h2>
                                <ul id="menu-quick-links" className="footer-menu-list">
                                    <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                        <a target="_blank" rel="noopener noreferrer" href="#">Support Center</a>
                                    </li>
                                    <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                        <a target="_blank" rel="noopener noreferrer" href="#">Service Status</a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="#">Security</a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="#">Blog</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-content-column">
                            <div className="footer-call-to-action">
                                <h2 className="footer-call-to-action-title"> Let's Chat</h2>
                                <p className="footer-call-to-action-description"> Have a support question?</p>
                                <a className="footer-call-to-action-button button" href="#" target="_self"> Get in Touch </a>
                            </div>
                        </div>
                        <div className="footer-social-links"> 
                            <svg className="footer-social-amoeba-svg"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236 54">
                                <path className="footer-social-amoeba-path"
                                    d="M223.06,43.32c-.77-7.2,1.87-28.47-20-32.53C187.78,8,180.41,18,178.32,20.7s-5.63,10.1-4.07,16.7-.13,15.23-4.06,15.91-8.75-2.9-6.89-7S167.41,36,167.15,33a18.93,18.93,0,0,0-2.64-8.53c-3.44-5.5-8-11.19-19.12-11.19a21.64,21.64,0,0,0-18.31,9.18c-2.08,2.7-5.66,9.6-4.07,16.69s.64,14.32-6.11,13.9S108.35,46.5,112,36.54s-1.89-21.24-4-23.94S96.34,0,85.23,0,57.46,8.84,56.49,24.56s6.92,20.79,7,24.59c.07,2.75-6.43,4.16-12.92,2.38s-4-10.75-3.46-12.38c1.85-6.6-2-14-4.08-16.69a21.62,21.62,0,0,0-18.3-9.18C13.62,13.28,9.06,19,5.62,24.47A18.81,18.81,0,0,0,3,33a21.85,21.85,0,0,0,1.58,9.08,16.58,16.58,0,0,1,1.06,5A6.75,6.75,0,0,1,0,54H236C235.47,54,223.83,50.52,223.06,43.32Z">
                                </path>
                            </svg>
                            <a className="footer-social-link linkedin" href="https://www.instagram.com/giivv_com/?hl=en" target="_blank">
                                <span className="hidden-link-text">Instagram</span>
                                <i className="fa-brands fa-instagram ms-1 fs-5 text-white"></i>
                            </a>
                            <a className="footer-social-link twitter" href="https://x.com/giivv_com" target="_blank">
                                <span className="hidden-link-text">Twitter</span>
                                <i className="fa-brands fa-twitter text-white fs-5 ms-1"></i>
                            </a>
                            <a className="footer-social-link youtube" href="https://www.youtube.com/@Giivv.comofficial" target="_blank">
                                <span className="hidden-link-text">Youtube</span>
                                <i className="fa-brands fa-youtube text-white fs-5"></i>
                            </a>
                            <a className="footer-social-link github" href=" https://www.facebook.com/profile.php?id=61566349427625" target="_blank">
                                <span className="hidden-link-text">Facebook</span>
                                <i className="fa-brands fa-facebook text-white ms-2 fs-5"></i>
                            </a>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <div className="footer-copyright-wrapper">
                            <p className="footer-copyright-text">
                                <a className="footer-copyright-link" href="https://perennialcode.in/" target="_blank"> ©2024. | Designed By: Perennialcode. |
                                    All rights reserved. </a>
                            </p>
                        </div>
                    </div>
                </footer>
            </div>            

            <CartItems />
            {/* <GenQRCode ProductId={0} /> */}

        </div>
    )
}

export default Base;