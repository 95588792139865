import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProductDetails from './Components/Product/ProductDetails';
import Products from './Components/Product/Products';
import GenerateQRCode from './Components/QRCode/GenQRCode';
import Home from './Components/Home/Home';


function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/product-list" element={<Products />} />
          <Route path="/product-info/:ProductId" element={<ProductDetails />} />
          <Route path="/" element={<Home />} />
          {/* <Route path="/generate-qrcode" element={<GenerateQRCode />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
