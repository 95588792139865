import { React, useEffect, useState } from "react";
import Base from './../Config/Base';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { API_BASE_URL, HARD_CODED_ORGID, IBIZ_IMAGE_URL } from "../Config/Config";
import Axios from 'axios';
import { Link } from "react-router-dom";
import Banner1 from '../../Assests/Images/banner1.jpg';
import Banner2 from '../../Assests/Images/banner2.jpg';
import Banner3 from '../../Assests/Images/banner3.jpeg';
import Banner4 from '../../Assests/Images/banner4.jpeg';
import DashboardImg from '../../Assests/Images/dashboard.png';
import CashbackImg from '../../Assests/Images/cashback.jpg';
import { Rating } from 'react-simple-star-rating'
import './Home.css'


export default function Home() {

    const [brandsList, setBrandsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showMoreClick, setShowMoreClick] = useState(false);
    const [categoriesList, setCategoriesList] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [allProductsData, setAllProductsData] = useState([]);
    const [selectedCat, setSelectedCat] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const rating = 4;

    useEffect(() => {
        if (HARD_CODED_ORGID) {
            Axios.get(`${API_BASE_URL}QRProduct/getBrands?OrgId=${HARD_CODED_ORGID}`)
                .then(response => {
                    if (response.data.Status) {
                        setBrandsList(response.data.ResultData);
                    } else {
                        console.error('Error fetching data:', response.data.Message);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
            Axios.get(`${API_BASE_URL}QRProduct/GetCategoryItems?OrgId=${HARD_CODED_ORGID}`)
                .then(response => {
                    if (response.data.Status) {
                        setCategoriesList(response.data.ResultData);
                    } else {
                        console.error('Error fetching data:', response.data.Message);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }, []);

    useEffect(() => {
        if (HARD_CODED_ORGID){
            setLoading(true);
            const api = `${API_BASE_URL}QRProduct/GetProductsByOrgId?OrgId=${HARD_CODED_ORGID}&BrandId=0&CategoryId=0&Type=1`;
            Axios.get(api)
                .then(response => {
                    if (response.data.Status) {
                        setProductsData(response.data.ResultData);
                        console.log(response.data.ResultData);
                        setLoading(false);
                    } else {
                        console.error('Error fetching data:', response.data);
                        setProductsData([]);
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                });
        }
    }, []);

    useEffect(() => {
        console.log(selectedCat, 'selectedCatselectedCat')
        if (HARD_CODED_ORGID && (selectedBrand || selectedCat)){
            setLoading(true);
            if (!selectedBrand) {
                setSelectedBrand(0);
            }
            if (!selectedCat) {
                setSelectedCat(0);
            }
            const api = `${API_BASE_URL}QRProduct/GetProductsByOrgId?OrgId=${HARD_CODED_ORGID}&BrandId=${selectedBrand}&CategoryId=${selectedCat}&Type=1`;
            Axios.get(api)
                .then(response => {
                    if (response.data.Status) {
                        setProductsData(response.data.ResultData);
                        console.log(response.data.ResultData);
                        setLoading(false);
                    } else {
                        console.error('Error fetching data:', response.data);
                        setProductsData([]);
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                });
        }
    }, [selectedCat, selectedBrand]);

    const getFirstLetter = (str) => {
        const match = str.match(/[a-zA-Z]/);
        return match ? match[0].toUpperCase() : "N/A";
    };

    const handleShowMore = () => {
        setShowMoreClick(true);
        if (HARD_CODED_ORGID && (selectedBrand || selectedCat)){
            console.log('handleshow call');
            if (!selectedBrand) {
                setSelectedBrand(0);
            }
            if (!selectedCat) {
                setSelectedCat(0);
            }
            // setLoading(true);
            const api = `${API_BASE_URL}QRProduct/GetProductsByOrgId?OrgId=${HARD_CODED_ORGID}&BrandId=${selectedBrand}&CategoryId=${selectedCat}&Type=0`;
            Axios.get(api)
                .then(response => {
                    if (response.data.Status) {
                        setAllProductsData(response.data.ResultData);
                        console.log(response.data.ResultData);
                        // setLoading(false);
                    } else {
                        console.error('Error fetching data:', response.data);
                        setAllProductsData([]);
                        // setLoading(false);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    // setLoading(false);
                });
        }
    };

    const handleGoBack = () => {
        setShowMoreClick(false);
    };

    const handleAddToCartClick = (item) => {
        const cartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
    
        const itemExists = cartItems.some(cartItem => cartItem.Id === item.Id);
    
        if (itemExists) {
            alert("Product already added to cart.");
        } else {
            cartItems.push({ ...item, quantity: 1 });
    
            sessionStorage.setItem('cartItems', JSON.stringify(cartItems));
    
            alert("Product added to cart!");
        }
    };    
    


    return (
        <Base>
            <div className="page-wrapper">
                <div className="content" style={{ marginTop: '-2rem' }}>
                    <div className="row align-items-start pos-wrapper">
                        <div className="col-12">
                            <div className={`pos-categories tabs_wrapper mt-5 0 ${showMoreClick ? 'd-none' : 'd-block'}`}>
                                {/* Category Caurosel */}
                                <h5>Categories</h5>
                                <p>Select From Below Categories</p>
                                <OwlCarousel 
                                    className="owl-theme" 
                                    loop 
                                    // margin={2} 
                                    nav
                                    dots={false}
                                    responsive={{
                                        0: { items: 2 },
                                        600: { items: 2 },
                                        1000: { items: 8 }
                                    }}
                                >
                                    <div 
                                        className="product-info default-cover active" 
                                        style={{ width: '280px', cursor: 'pointer' }}
                                        onClick={() => setSelectedCat(0)}
                                    >
                                        <div 
                                            className="m-auto mt-4 mb-5" 
                                            style={{ 
                                                height: '7rem', 
                                                display: 'flex', 
                                                flexDirection: 'column', 
                                                alignItems: 'center', 
                                                justifyContent: 'center',
                                                textAlign: 'center' 
                                            }}
                                        >
                                            <li id="all" style={{ listStyle: 'none' }}>
                                                <a className="m-auto d-flex text-center">
                                                    <img 
                                                        src={DashboardImg}
                                                        alt="Category"
                                                        style={{ 
                                                            width: '7rem', 
                                                            height: '7rem',
                                                        }}
                                                        className="m-auto d-flex text-center "
                                                    />
                                                </a>
                                                <h6 className="mt-2">
                                                    <a  className="text-dark">All Categories</a>
                                                </h6>
                                                <span className="text-info fw-semibold">
                                                    {categoriesList.reduce((total, category) => total + category.ProductCount, 0)} Items
                                                </span>
                                            </li>
                                        </div>
                                    </div>

                                    {categoriesList && categoriesList.map((item, index) => (
                                        <div 
                                            className="mt-4 mb-5" 
                                            key={index} 
                                            style={{ width: '280px', cursor: 'pointer' }}
                                            onClick={() => setSelectedCat(item.CategoryId)}
                                            title={item.Name}
                                        >
                                            <div className="m-auto" 
                                                style={{ 
                                                    height: '7rem', 
                                                    display: 'flex', 
                                                    flexDirection: 'column', 
                                                    alignItems: 'center', 
                                                    justifyContent: 'center',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <li id={item.CategoryId || `brand-${index}`} style={{ listStyle: 'none' }}>
                                                    <a>
                                                        <img 
                                                            src={`${IBIZ_IMAGE_URL}${item.ImagePath}`} 
                                                            alt="Category"
                                                            style={{ 
                                                                width: '7rem', 
                                                                height: '7rem',
                                                                borderRadius: '10px'
                                                            }}
                                                            className="m-auto d-flex text-center "
                                                        />
                                                    </a>
                                                    <h6 className="mt-2" title={item.Name}>
                                                        <a className="text-dark fw-bold">
                                                            {item.Name.length > 13 ? `${item.Name.substring(0, 13)}...` : item.Name}
                                                        </a>
                                                    </h6>
                                                    <span className="text-info fw-semibold">{item.ProductCount} Items</span>
                                                </li>
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>

                                {/* Images Caurosel */}
                                <OwlCarousel 
                                    className="owl-theme my-2" 
                                    loop 
                                    // nav
                                    dots={false}
                                    responsive={{
                                        0: { items: 1 },
                                        600: { items: 1 },
                                        1000: { items: 1 }
                                    }}
                                >
                                    <div>
                                        <img src={Banner1} style={{ borderRadius: '10px' }} alt="Banner 1" />
                                    </div>
                                    <div>
                                        <img src={Banner2} style={{ borderRadius: '10px' }} alt="Banner 2" />
                                    </div>
                                    <div>
                                        <img src={Banner3} style={{ borderRadius: '10px' }} alt="Banner 3" />
                                    </div>
                                    <div>
                                        <img src={Banner4} style={{ borderRadius: '10px' }} alt="Banner 4" />
                                    </div>
                                </OwlCarousel>

                                {/* Brands Caurosel */}
                                <h5>Brands</h5>
                                <p>Select From Below Brands</p>
                                <OwlCarousel 
                                    className="owl-theme" 
                                    loop 
                                    margin={6} 
                                    nav
                                    dots={false}
                                    responsive={{
                                        0: { items: 2 },
                                        600: { items: 2 },
                                        1000: { items: 8 }
                                    }}
                                >
                                    <div 
                                            className="product-info default-cover" 
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => setSelectedBrand(0)}

                                        >
                                            <div className="mt-4 mb-5" 
                                                style={{ 
                                                    height: '6rem', 
                                                    display: 'flex', 
                                                    flexDirection: 'column', 
                                                    alignItems: 'center', 
                                                    justifyContent: 'center' 
                                                }}
                                            >
                                                <li id="all" style={{ 
                                                    listStyle: 'none', 
                                                    textAlign: 'center' 
                                                }}>
                                                    <a >
                                                        <img 
                                                            src={DashboardImg}
                                                            alt="Brands"
                                                            style={{ 
                                                                width: '7rem', 
                                                                height: '7rem',
                                                            }}
                                                            className="rounded d-flex m-auto"
                                                        />
                                                    </a>
                                                    <h6 style={{ marginTop: '0.5rem' }}>
                                                        <a >All Brands</a>
                                                    </h6>
                                                </li>
                                            </div>
                                        </div>
                                    {brandsList && brandsList.map((item, index) => (
                                        <div 
                                            className="product-info default-cover " 
                                            key={index} 
                                            style={{ width: '150.555px', cursor: 'pointer' }}
                                            onClick={() => setSelectedBrand(item.Id)}
                                            title={item.Name}
                                        >
                                            <div className="mt-4 mb-5" style={{ 
                                                height: '6rem', 
                                                display: 'flex', 
                                                flexDirection: 'column', 
                                                alignItems: 'center', 
                                                justifyContent: 'center' 
                                            }}>
                                                <li id={item.Id || `brand-${index}`} style={{ 
                                                    listStyle: 'none', 
                                                    textAlign: 'center' 
                                                }}>
                                                    <a >
                                                        <img 
                                                            src={`${IBIZ_IMAGE_URL}${item.Filepath}`} 
                                                            alt="Brands"
                                                            style={{ 
                                                                width: '7rem', 
                                                                height: '7rem', 
                                                                borderRadius: '10px'
                                                            }}
                                                            className="rounded d-flex m-auto"
                                                        />
                                                    </a>
                                                    <h6 style={{ marginTop: '0.5rem' }} title={item.Name}>
                                                        {item.Name.length > 13 ? `${item.Name.substring(0, 13)}...` : item.Name}
                                                    </h6>
                                                </li>
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                                
                                <div className="pos-products">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5 className="mb-3">Products</h5>
                                    </div>
                                    <div className="tabs_container" >
                                        <div className="tab_conten" data-tab="all">
                                            <div className="row">
                                                {loading ? (
                                                    <h6 className="text-center">Loading..</h6>
                                                ) : productsData && productsData.length > 0 ? (
                                                    productsData.map((item, index) => (
                                                        <div 
                                                            className="col-sm-2 col-md-6 col-lg-3 col-xl-3" 
                                                            key={index}
                                                        >
                                                            <div className="product-info default-cover card position-relative" 
                                                                onMouseEnter={() => setHoveredIndex(index)}
                                                                onMouseLeave={() => setHoveredIndex(null)}
                                                            >
                                                                <a className="img-bg">
                                                                    {item.ImagePath ? (
                                                                        <img
                                                                            src={`${IBIZ_IMAGE_URL}${item.ImagePath}`}
                                                                            alt=""
                                                                            style={{
                                                                                width: '6rem'
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <div
                                                                            style={{
                                                                                width: "80px",
                                                                                height: "80px",
                                                                                backgroundColor: "#FF9F43",
                                                                                marginRight: "10px",
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                borderRadius: "5%",
                                                                                fontSize: "25px",
                                                                                color: "#fff",
                                                                            }}
                                                                        >
                                                                            {item ? getFirstLetter(item.Name) : "N/A"}
                                                                        </div>
                                                                    )}
                                                                </a>
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <div>
                                                                        <h6 className="cat-name mb-1">
                                                                            <a>{item.CategoryName ? item.CategoryName : 'N/A'}</a>
                                                                        </h6>
                                                                        <h6 className="product-name mb-1">
                                                                            <Link to={`/product-info/${item.Id}`}>{item.Name}</Link>
                                                                        </h6>
                                                                    </div>
                                                                    <div>
                                                                        <Rating initialValue={rating} size={20} />
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-items-center justify-content-between price">
                                                                    <span>{item.BarCode || 'N/A'}</span>
                                                                    <p className="fw-semibold">₹{item.AvgPrice || 0}</p>
                                                                </div>
                                                                {hoveredIndex === index && (
                                                                    <div className="position-absolute bottom-2 top-0 start-1 end-0 d-flex justify-content-center">
                                                                        <button 
                                                                            className="btn btn-primary fade-in"  
                                                                            onClick={() => handleAddToCartClick(item)}
                                                                            type="button"
                                                                        >
                                                                            <i className="fa-solid fa-cart-shopping"></i>
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="text-center mt-3">No data available</div>
                                                )}
                                            </div>

                                            <h4 
                                                className="text-end"
                                                style={{ cursor: 'pointer' }}
                                                onClick={handleShowMore}
                                            >
                                                Show More <i className="fa-solid fa-ellipsis"></i>
                                            </h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="my-3">
                                    <img 
                                        src={CashbackImg} 
                                        alt="Cashback Image" 
                                        style={{ borderRadius: '10px' }}
                                    />
                                </div>
                            </div>




                            {/* Show All products */}
                            <div className={`pos-categories tabs_wrapper ${showMoreClick ? 'd-block' : 'd-none'}`} style={{ marginTop: '3rem' }}>
                                <div className="pos-products">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5 className="mb-3">Products</h5>
                                        <h4 
                                            className={`text-end ${showMoreClick ? 'd-block' : 'd-none'}`}
                                            style={{ cursor: 'pointer' }}
                                            onClick={handleGoBack}
                                        >
                                            <i className="fa-solid fa-arrow-left"></i> Go Back
                                        </h4>
                                    </div>
                                    <div className="tabs_container" >
                                        <div className="tab_conten" data-tab="all">
                                            <div className="row">
                                                {allProductsData && allProductsData.map((item, index) => (
                                                    <div 
                                                        className="col-sm-2 col-md-6 col-lg-3 col-xl-3" 
                                                        key={index}
                                                    >
                                                        <div className="product-info default-cover card position-relative"
                                                            onMouseEnter={() => setHoveredIndex(index)}
                                                            onMouseLeave={() => setHoveredIndex(null)}
                                                        >
                                                            <a  className="img-bg">
                                                                {item.ImagePath ? (
                                                                    <img
                                                                        src={`${IBIZ_IMAGE_URL}${item.ImagePath}`}
                                                                        alt=""
                                                                        style={{
                                                                            width: '6rem'
                                                                        }}
                                                                    />
                                                                    ) : (
                                                                    <div
                                                                        style={{
                                                                        width: "80px",
                                                                        height: "80px",
                                                                        backgroundColor: "#FF9F43",
                                                                        marginRight: "10px",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        borderRadius: "5%",
                                                                        fontSize: "25px",
                                                                        color: "#fff",
                                                                        }}
                                                                    >
                                                                        {item ? getFirstLetter(item.Name) : "N/A"}
                                                                    </div>
                                                                    )}
                                                            </a>
                                                            <h6 className="cat-name"><a >{item.CategoryName ? item.CategoryName : 'N/A'}</a></h6>
                                                            <h6 className="product-name"><Link to={`/product-info/${item.Id}`}>{item.Name}</Link></h6>
                                                            <div className="d-flex align-items-center justify-content-between price">
                                                                <span>{item.BarCode || 'N/A'}</span>
                                                                <p className="fw-semibold">₹{item.AvgPrice || 0}</p>
                                                            </div>
                                                            {hoveredIndex === index && (
                                                                <div className="position-absolute bottom-2 top-0 start-1 end-0 d-flex justify-content-center">
                                                                    <button 
                                                                        className="btn btn-primary fade-in"  
                                                                        onClick={() => handleAddToCartClick(item)}
                                                                        type="button"
                                                                    >
                                                                        <i className="fa-solid fa-cart-shopping"></i>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <h4 
                                                className={`text-end ${showMoreClick ? 'd-none' : 'd-block'}`}
                                                style={{ cursor: 'pointer' }}
                                                onClick={handleShowMore}
                                            >
                                                Show More <i className="fa-solid fa-ellipsis"></i>
                                            </h4>
                                            <h4 
                                                className={`text-end ${showMoreClick ? 'd-block' : 'd-none'}`}
                                                style={{ cursor: 'pointer' }}
                                                onClick={handleGoBack}
                                            >
                                                <i className="fa-solid fa-arrow-left"></i> Go Back
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Base>
    );
}
